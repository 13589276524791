import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import TwoColumn from "../components/TwoColumn"
import Testimonials from "../components/Testimonials"
import CTA from "../components/CTA"
import TitleIconColumns from "../components/TitleIconColumns"

export const query = graphql`
  query Distributor {
    page: allWpPage(filter: { slug: { eq: "distributor" } }) {
      edges {
        node {
          nodeType
          pageTitle: title
          acfSubPageBanner {
            subPageBannerImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sloganText: subPageBannerSloganText
            sloganTitle: subPageBannerSloganTitle
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          acfTestimonials {
            testimonialShow
            testimonialCard {
              testimonialAuthorCompany
              testimonialAuthorName
              testimonialContent
              testimonialImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 325) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          acfDistributor {
            disributorIntroductionTitleTagline
            distributorIntroductionTitle
            distributorOptions {
              distributorLink
              distributorText
              distributorIcon {
                localFile {
                  publicURL
                }
              }
            }
            distributorTwoColumnRow {
              description: distributorTwoColDescriptionContent
              mainTitle: distributorTwoColMainTitle
              subTitle: distributorTwoColSubTitle
              buttonTextLink: distributorTwoColButtonTextLink {
                target
                title
                url
              }
              twoColImage: distributorTwoColImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 680) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ...SEO
        }
      }
    }
  }
`

const DistributorPage = ({ location, data }) => {
  const {
    seo,
    acfSubPageBanner,
    acfDistributor,
    acfCta,
    acfTestimonials,
  } = data.page.edges[0].node

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />

      <SubBanner
        mainTitle={acfSubPageBanner.sloganTitle}
        subTitle={acfSubPageBanner.sloganText}
        image={acfSubPageBanner?.subPageBannerImage?.localFile?.childImageSharp?.fluid}
      />

      <TitleIconColumns
        subTitle={acfDistributor.disributorIntroductionTitleTagline}
        mainTitle={acfDistributor.distributorIntroductionTitle}
        items={acfDistributor.distributorOptions}
        iconFieldName="distributorIcon"
        textFieldName="distributorText"
        fieldLink="distributorLink"
      />

      <TwoColumn data={acfDistributor.distributorTwoColumnRow} />

      {acfTestimonials.testimonialShow && (
        <Testimonials data={acfTestimonials.testimonialCard} />
      )}

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default DistributorPage
